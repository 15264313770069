module.exports = [{
      plugin: require('/Users/tt/atelier-seal/experimental/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('/Users/tt/atelier-seal/experimental/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tt/atelier-seal/experimental/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tt/atelier-seal/experimental/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tt/atelier-seal/experimental/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
